import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/public/auth/Login.vue'),
    meta: { layout: 'auth-layout', private: false },
  },
  {
    path: '/registro',
    name: 'register',
    component: () => import('../views/public/auth/Register.vue'),
    meta: { layout: 'auth-layout', private: false },
  },
  {
    path: '/recuperar',
    name: 'reset',
    component: () => import('../views/public/auth/Reset.vue'),
    meta: { layout: 'auth-layout', private: false },
  },
  {
    path: '/cambiar-password',
    name: 'changePassword',
    component: () => import('../views/public/auth/ChangePassword.vue'),
    meta: { layout: 'auth-layout', private: false },
  },
  {
    path: '/propuestas-usuario',
    name: 'awardProposals',
    component: () =>
      import('../views/private/award-proposals/AwardProposals.vue'),
    meta: { private: true },
  },
  {
    path: '/propuestas-usuario/crear',
    name: 'createAwardProposal',
    component: () =>
      import('../views/private/award-proposal/AwardProposal.vue'),
    meta: { private: true },
  },
  {
    path: '/propuestas-usuario/editar/:id',
    name: 'editAwardProposal',
    component: () =>
      import('../views/private/award-proposal/AwardProposal.vue'),
    meta: { private: true },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error404.vue'),
    meta: { layout: 'full-width-layout', private: false },
  },
  {
    path: '*',
    redirect: '/error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, source, next) => {
  let authenticated = false;

  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    if (user.token) {
      authenticated = true;
    }
  }

  if (to.meta.private && !authenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;

import axios from "axios";
import eventHub from "@/eventhub";

axios.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : "";
  config.baseURL = process.env.VUE_APP_API_URL;
  config.headers.common = {
    [process.env.VUE_APP_API_TOKEN_HEADER]:
      `${process.env.VUE_APP_API_TOKEN_PREFIX}` + " " + token,
  };
  return config;
});

axios.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          if (!error.response.config.url.includes("login")) {
            localStorage.clear();
            eventHub.$emit("error_401");
          }
          break;
        case 404:
          eventHub.$emit("error_404");
          break;
      }
    } else {
      eventHub.$emit("error_503");
    }
    return Promise.reject(error);
  }
);

export default {
  install(Vue) {
    Vue.prototype.$axios = axios;
  },
};

<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-main>
      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import NavBar from "@/layouts/components/NavBar";

export default {
  name: "MainLayout",
  components: {NavBar},
  data: () => {
    return {};
  },
  methods: {},
};
</script>

export const ROLES_CONSTANTS = {
  user: {
    code: 1,
    permissions: [
      'CREATE_PROPOSAL',
      'DELETE_PROPOSAL',
      'EDIT_PROPOSAL'
    ]
  },
  admin: {
    code: 2,
    permissions: [
      'VIEW_PROPOSALS',
      'VIEW_INFO_COLUMNS',
      'VIEW_ACTIONS_COLUMNS',
      'VIEW_USERS_WITHOUT_PROPOSAL',
      'EXPORT_TO_EXCEL'
    ]
  },
  evaluator: {
    code: 3,
    permissions: [
      'VIEW_PROPOSALS',
      'VIEW_INFO_COLUMNS',
      'VIEW_USERS_WITHOUT_PROPOSAL',
      'EXPORT_TO_EXCEL'
    ]
  }
}

export default ROLES_CONSTANTS

import rolesConstants from "../constants/RolesConstants";

const sessionMixin = {
  methods: {
    saveToLocalStorage(user) {
      localStorage.setItem("user", JSON.stringify(user));
    },
    getUser() {
      const userInfo = JSON.parse(localStorage.getItem("user"));
      if (userInfo && userInfo.user) {
        return {
          name: userInfo.user.name,
          lastName: userInfo.user.lastName,
          username: userInfo.user.username,
          roleId: userInfo.user.roleId,
          token: userInfo.token,
        };
      }
      return null;
    },
    getRoleById(roleId) {
      for (const roleName in rolesConstants) {
        const role = rolesConstants[roleName];
        if (roleId === role.code) {
          return role;
        }
      }
      return null;
    },
    isLoggedIn() {
      return this.getUser() != null;
    },
    clear() {
      localStorage.clear();
    },
    isUser() {
      const user = this.getUser();
      if (user) {
        const role = this.getRoleById(user.roleId);
        if (role.code === rolesConstants.user.code) {
          return true;
        }
      }
      return false;
    },
    isAdmin() {
      const user = this.getUser();
      if (user) {
        const role = this.getRoleById(user.roleId);
        if (role.code === rolesConstants.admin.code) {
          return true;
        }
      }
      return false;
    },
    isEvaluator() {
      const user = this.getUser();
      if (user) {
        const role = this.getRoleById(user.roleId);
        if (role.code === rolesConstants.evaluator.code) {
          return true;
        }
        return false;
      }
    },
    hasPermission(permissionCode) {
      const user = this.getUser();
      if (user) {
        const role = rolesConstants[user.role];
        if (role) {
          return role.permissions.includes(permissionCode);
        }
      }
      return false;
    },
  },
};
export default sessionMixin;

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

export default {
  install (Vue) {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule])
    })

    extend('maxWords', {
      params: ['length'],
      validate(value,{length}) {
        if (value) {
          const words = value.trim().split(/[\s\n ]+/)
          return words.length <= Number(length)
        }
        return false
      },
      message: (fieldName,placeHolders) => {
        return `El campo ${fieldName} debe tener máximo ${placeHolders.length} palabras.`
      }
    })

    localize('es', es)
  }
}

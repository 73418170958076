<template>
  <div>
    <component :is="layout">
      <router-view :layout.sync="layout"/>
    </component>
    <vue-snotify/>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"/>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import eventHub from '@/eventhub';
import notificationMixin from '@/mixins/notificationMixin';
const defaultLayout = "main-layout";

export default {
  name: "App",
  mixins: [notificationMixin],
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      fullPage: true
    }
  },
  created() {
    eventHub.$on('error_503', () => {
      this.showError('Servicio no disponible')
    })

    eventHub.$on('error_401', () => {
      this.$router.push({name: 'login'})
    })

    eventHub.$on('error_404', () => {
      this.$router.push({name: 'error-404'})
    })

    eventHub.$on('show_loader', () => {
      this.isLoading = true
    })

    eventHub.$on('hide_loader', () => {
      this.isLoading = false
    })
  },
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
};
</script>

export default {
  methods: {
    createSuccessNotify() {
      this.$snotify.success(
        "El registro fue creado exitosamente",
        "Operación exitosa",
        {
          timeout: 2000,
          titleMaxLength: 30,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        }
      );
    },
    createFailNotify() {
      this.$snotify.error(
        "El registro no pudo ser creado",
        "Operación NO exitosa",
        {
          timeout: 2000,
          titleMaxLength: 30,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        }
      );
    },
    deleteSuccessNotify() {
      this.$snotify.success(
        "El registro fue eliminado exitosamente",
        "Operación exitosa",
        {
          timeout: 2000,
          titleMaxLength: 30,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        }
      );
    },
    updateSuccessNotify() {
      this.$snotify.success(
        "El registro fue actualizado exitosamente",
        "Operación exitosa",
        {
          timeout: 2000,
          titleMaxLength: 30,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        }
      );
    },
    updateFailNotify() {
      this.$snotify.error(
        "El registro no pudo ser actualizado",
        "Operación NO exitosa",
        {
          timeout: 2000,
          titleMaxLength: 30,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        }
      );
    },
    showSuccess(message) {
      this.$snotify.success(message, "Operación exitosa", {
        timeout: 2000,
        showProgressBar: true,
        titleMaxLength: 30,
        closeOnClick: false,
        pauseOnHover: true,
      });
    },
    showError(error) {
      this.$snotify.error(
        error || "Ha ocurrido un error en el servidor",
        "Error",
        {
          timeout: 2000,
          titleMaxLength: 30,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        }
      );
    },
    invalidFields() {
      this.$snotify.error(
        "Algunos campos son inválidos",
        "Error de validación",
        {
          timeout: 2000,
          titleMaxLength: 30,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        }
      );
    },
  },
};

<template>
  <div>
    <v-system-bar color="#222c33" height="35"></v-system-bar>
    <div
        class="container--fluid"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
      <div class="px-7">
        <div class="d-flex">
          <div class="d-flex align-center col-6 col-md-6 px-0 py-0">
            <img
                src="@/assets/images/logo-fise-inv.svg"
                alt="logo"
                width="400px"
            />
          </div>

          <div class="col-6 col-md-6 d-flex justify-end align-center">
            <img
              class="mr-5 d-none d-md-block"
              src="@/assets/images/logo-fise-start.svg"
              alt="logo"
              height="70px"
            />
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    depressed
                    class="text-none"
                    v-bind="attrs"
                    v-on="on"
                    dark
                >
                  {{ displayName }}
                  <v-icon class="ml-2" color="white" size="10">
                    fas fa-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item>
                    <v-list-item-title @click="logout">
                      Salir
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sessionMixin from "@/mixins/sessionMixin";

export default {
  name: "NavBar",
  mixins: [sessionMixin],
  methods: {
    logout() {
      this.clear();
      this.$router.push({path: "/"});
    },
  },
  computed: {
    displayName() {
      const user = this.getUser();
      if (user) {
        return user.name;
      } else {
        return "Usuario";
      }
    }
  }
};
</script>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "vue-snotify/styles/material.css";
import Snotify from "vue-snotify";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import veeValidate from "./plugins/vee-validate";
import MainLayout from "./layouts/MainLayout";
import FullWidthLayout from "./layouts/FullWidthLayout";
import AuthLayout from "./layouts/AuthLayout";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";
import "@/assets/scss/main.scss";

Vue.use(axios);
Vue.use(Snotify);
Vue.use(veeValidate);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false;

// Layouts
Vue.component("main-layout", MainLayout);
Vue.component("full-width-layout", FullWidthLayout);
Vue.component("auth-layout", AuthLayout);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
